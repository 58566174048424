<template>
  <div class="page">
    <div class="header-section">
      <h1 class="page-title">Afriziki <span>Radio Stations</span></h1>

      <div class="countries__container">
          <div v-for="country in countryStations" :key="country.name" class="country__wrapper">
          <h2 class="country__name">{{country.name}}</h2>
          <div class="stations__wrapper">
              <p class="station__name" v-for="(station, i) in country.stations" :key="country + station + i">{{station}}</p>
          </div>
      </div>
      </div>
    </div>
    <Loader v-if="isLoading" />
  </div>
</template>

<script>
import { getCurrentWeekDates } from "../services/ops";
import baseAPI from "../services/api";
import Loader from "../components/Loader.vue";

export default {
  components: { Loader },
  mounted() {
    // this.fetchCountries();
    this.fetchStations();
  },
  data() {
    return {
      countries: [],
      entries: [],
      country: "",
      count: 20,
      isLoading: false,
      start_date: "",
      end_date: "",
      currentPeriod: "monthly",
      countryStations: []
    };
  },
  // computed: {
  //   stations() {

  //   }
  // },
  methods: {
    fetchCountries() {
      this.isLoading = true;
      baseAPI
        .get(`/public/countries`)
        .then((response) => {
          this.countries = response.data.countries;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    fetchStations() {
        this.isLoading = true;

        baseAPI.get('/public/stations')
            .then(response => {

                const data = response.data.stations;

                const compiled = [];

                data.map(item => {
                    const idx = compiled.findIndex(e => e.name == item.country_name);

                    if(idx >= 0) {
                        compiled[idx].stations.push(item.station_name);
                    }else {
                        compiled.push({
                            name: item.country_name,
                            stations: [item.station_name]
                        })
                    }

                })

                // sort based on stations length

                compiled.sort((a, b) => {
                    return b.stations.length - a.stations.length;
                })

                this.countryStations = compiled;

                this.countryStations.map(country => {
                  country.stations.sort();
                })

                this.isLoading = false;
            })
            .catch(err =>  {
                console.log(err);
            })
    }
  },
};
</script>

<style scoped>
.countries__container {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 3em;
}

.country__wrapper {
    text-align: left;
    margin-top: 2em;
    /* min-width: 360px; */
    /* max-width: 50%; */
}

.country__name {
    text-transform: capitalize;
    margin-bottom: .5em;
    font-size: 40px;
    font-weight: bold;
}

.stations__wrapper {
    display: flex;
    flex-wrap: wrap;
}

.station__name {
    font-size: 13px;
    color: rgb(66, 66, 66);
    min-width: 250px;
    margin-bottom: 0px;
    text-transform: capitalize;
}
</style>
