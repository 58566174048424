import Charts from './views/MusicCharts.vue';
import Releases from './views/Releases.vue';
import Countries from './views/Countries.vue';

export const routes = [
    { path: "/", component: Charts, name: "Charts" },
    { path: "/releases", component: Releases, name: "Releases" },
    { path: "/countries", component: Countries, name: "Countries" }
]

