export const createDateString = (d) => {
    const date = new Date(d)
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}


export function getCurrentWeekDates(week) {
    // takes in 1 for current week and -1 for last week
    const today = new Date();
    const dayCount = week > 0 ? today.getDay() : today.getDay() + 7;
    const startOfWeekTime =  today.getTime() - ((dayCount) * 24 * 3600 * 1000);

    const startWeekDate = new Date(startOfWeekTime);
    const endWeekCount = week < 0 ? today.getDay()  : 0;
    const endWeekTime = today.getTime() - (endWeekCount * 24 * 3600 * 1000);
    const endWeekDate = new Date(endWeekTime);

    const dates = {start_date: createDateString(startWeekDate), end_date: createDateString(endWeekDate)};

    return dates;
}