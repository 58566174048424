<template>
  <div id="app">
    <div class="nav-bar">
      <div class="logo">
        <img class="logo-img" src="./assets/solid_logo_white.png" alt="" />
        <span>Charts</span>
      </div>
    </div>
    <div class="page-controls">
      <div
        @click="$router.push('/')"
        :class="
          currentComponent == 'Charts' ? 'control-pill current' : 'control-pill'
        "
      >
        Afriziki Charts
      </div>

      <div
        @click="$router.push('/releases')"
        :class="
          currentComponent == 'Releases'
            ? 'control-pill current'
            : 'control-pill'
        "
        v-if="false"
      >
        Top Releases
      </div>

      <div
        @click="$router.push('/countries')"
        :class="
          currentComponent == 'Countries'
            ? 'control-pill current'
            : 'control-pill'
        "
      >
        Countries
      </div>

    </div>
    <transition
      name="routerview"
      appear
      enter-active-class="animate__animated animate__fadeInUp delay-5s"
      leave-active-class="animate__animated animate__fadeOutRight"
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    this.currentComponent = this.$router.history.current.name;
  },
  watch: {
    $route() {
      this.currentComponent = this.$router.history.current.name;
    },
  },
  data() {
    return { currentComponent: "" };
  },
};
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* height: 100vh; */
}

.animate__fadeInUp,
.animate__fadeOutRight {
  animation-duration: 0.5s;
}

.delay-5s {
  animation-delay: 0.5s;
}

.nav-bar {
  width: 100%;
  height: 80px;
  background-color: var(--color-dark-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
}

.logo > span > strong {
  color: var(--color-light) !important;
  font-weight: bold;
  font-size: 20px;
}
.logo {
  color: var(--color-highlight);
  margin: 0 40px;
  display: flex;
  align-items: flex-end;
}

.logo > span {
  font-size: 18px;
  margin-left: 4px;
  margin-bottom: 3px;
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
}

.logo-img {
  height: 60px;
}

.page-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* margin-top: 20px; */
}

@media all and (max-width: 600px) {
  .page-controls > * {
    width: 50% !important;
  }

  .nav-bar {justify-content: center;}
}
</style>
