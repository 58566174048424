<template>
  <div class="card chart-entry">
      <div class="card-left">
        <span class="entry-number">{{number}}.</span>
        <div class="entry-info">
            <p class="entry-name">{{data.media_info.artist_song_name}}</p>
            <p class="entry-artist">{{data.media_info.artist_name}}</p>
        </div>
      </div>

      <div :style="{'background-image': `url(${data.media_info.afriziki_thumbnail})`}" class="card-right entry-thumbnail" />
  </div>
</template>

<script>
export default {
    props: {
        number: {type: Number, default: 0, required: true},
        data: {type: Object, required: true, default: null}
    }
}
</script>

<style scoped>
    .chart-entry {
        height: 120px;
        width: 100%;
        flex-direction: row;
        align-items: center;
        padding-left: 32px;
        justify-content: space-between;
        background-position: center top;
        background-size: contain;
    }

    .entry-number {color: var(--color-highlight); font-size: 60px; font-family: var(--heading-font); font-weight: bold; min-width: 100px;}

    .card-left {display: flex; align-items: center; width: calc(100% - 160px);}

    .entry-info { margin-left: 2em;}

    .entry-name {font-family: var(--heading-font); font-weight: 500; color: var(--color-dark-grey); font-size: 18px; margin-bottom: 0; overflow: hidden; text-transform: capitalize;}

    .entry-artist {color: var(--color-info); font-size: 14px; font-weight: 300; text-transform: capitalize;}

    .entry-thumbnail {width: 160px; height: 120px;}

    @media all and (max-width: 600px) {

        .chart-entry {padding-left: 20px;}

        .entry-number {font-size: 36px; min-width: 20px;}

        .card-left {width: calc(100% - 80px)}

        .entry-info {margin-left: 1.25em;}

        .entry-thumbnail {width: 80px;}

        .entry-name {font-size: 15px; font-weight: bold;}

        .entry-artist {margin-top: 0.25em;}
    }
</style>