<template>
  <div class="page">
    <div class="header-section">
      <h1 class="page-title">Afriziki <span>Charts</span></h1>
      <div class="chart-region">
        <select
          @change="fetchEntries"
          v-model="country"
          class="custom-select"
          name="region"
          id="region"
        >
          <option value="">All</option>
          <option
            v-for="(country, i) in countries"
            :key="i + `${country.country_id}`"
            :value="country.country_id"
            >{{ country.country_name }}</option
          >
        </select>
        top
        <select
          v-model="count"
          class="custom-select"
          name="chart-size"
          id="chart-size"
          @change="fetchEntries"
        >
          <option value="20">20</option>
          <option selected value="30">30</option>
        </select>
      </div>

      <!-- <img class="hero-img" src="img/charts.jpg" alt="" /> -->
    </div>

    <div v-if="!isLoading" class="body-section">
      <div class="date-controls">
        <div
          :class="currentPeriod == 'thisWeek' ? 'control-pill current' : 'control-pill'"
          @click="
            fetchDateRangeEntries(1);
            currentPeriod = 'thisWeek';
          "
        >
          This Week <span class="no-mobile">&nbsp; (from Sunday)</span>
        </div>

        <div
          :class="currentPeriod == 'lastWeek' ? 'control-pill current' : 'control-pill'"
          @click="
            fetchDateRangeEntries(-1);
            currentPeriod = 'lastWeek';
          "
        >
          Last Week
        </div>

        <div
          :class="currentPeriod == 'monthly' ? 'control-pill current' : 'control-pill'"
          @click="
            start_date = '';
            end_date = '';
            currentPeriod = 'monthly';
            fetchEntries();
          "
        >
          This Month
        </div>
      </div>
      <ChartEntry
        v-for="(entry, i) in entries"
        :number="i + 1"
        :key="i + entry.song_id"
        :data="entry"
      />
    </div>
    <Loader v-if="isLoading" />
  </div>
</template>

<script>
import { getCurrentWeekDates } from "../services/ops";
import baseAPI from "../services/api";
import ChartEntry from "../components/ChartEntryCard.vue";
import Loader from "../components/Loader.vue";

export default {
  components: { ChartEntry, Loader },
  mounted() {
    this.fetchCountries();
    this.fetchEntries();
  },
  data() {
    return {
      countries: [],
      entries: [],
      country: "",
      count: 20,
      isLoading: false,
      start_date: "",
      end_date: "",
      currentPeriod: "monthly",
    };
  },
  methods: {
    fetchCountries() {
      this.isLoading = true;
      baseAPI
        .get(`/public/countries`)
        .then((response) => {
          this.countries = response.data.countries;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.toJSON());
          this.isLoading = false;
        });
    },

    fetchEntries() {
      const { country, count, start_date, end_date } = this;
      const dateParams =
        start_date.length || end_date.length
          ? `&start_date=${start_date}&end_date=${end_date}`
          : "";

      this.isLoading = true;
      baseAPI
        .get(
          `/public/chart?limit=${count}${
            country.length ? "&country=" + country : ""
          }${dateParams}`
        )
        .then((response) => {
          this.entries = response.data.results;
          this.isLoading = false;
        })
        .catch((error) => console.log(error.toJSON()));
    },

    fetchDateRangeEntries(week) {
      const dates = getCurrentWeekDates(week);
      this.start_date = dates.start_date;
      this.end_date = dates.end_date;

      this.fetchEntries();
    },
  },
};
</script>

<style scoped>
.page {width: 100%;}

.hero-img {
  width: 100%;
}

.chart-region {
  margin-bottom: 1em;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  width: 100%;
}

.body-section {
  width: 75%;
  margin-top: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.date-controls {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2em;
}

@media all and (max-width: 600px) {
  .chart-region {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2em;
  }

  .chart-region > select {width: 38%;}

  .hero-img { max-width: 100%;}

  .date-controls > * {width: 33% !important;}

  .body-section {width: 90%;}

  .no-mobile {display: none;}

}
</style>
