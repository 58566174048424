import {BASE_URL} from '../env'

import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    timeout: 6000000,
    timeoutErrorMessage: "Request timed out",
});

export default axiosInstance;