<template>
  <div class="lottie__wrapper">
    <div class="lottie__overlay">
      <lottie-animation
        ref="anim"
        :animationData="lottiePath"
        :loop="true"
        :autoPlay="true"
        :speed="0.5"
      />
      <span class="loading-text">Just a moment...</span>
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue/src";
import animationData from '../assets/lottie/music_wave.json';

export default {
  components: { LottieAnimation },
 data() {
     return {lottiePath: animationData }
 },
  methods: {},
};
</script>

<style lang="css" scoped>
.lottie__overlay {
  z-index: 1;
  mix-blend-mode: luminosity;
  width: 300px;
  height: 200px;
  margin-top: -100px;
  display: flex;
  flex-direction: column;
  text-align:center;
}

.loading-text {margin-top: -140px; color: var(--color-grey); font-weight: 300;}
</style>
    
