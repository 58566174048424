<template>
  <div class="page">
    <div class="header-section">
      <h1 class="page-title"><span>Afriziki</span> Top Releases</h1>
      <!-- <img class="hero-img" src="img/releases.jpg" alt="" /> -->
    </div>

    <transition name="release-list" appear enter-active-class="animate__animated animate__fadeInLeft delay-5s" leave-active-class="animate__animated animate__fadeOutRight">
    <div v-if="!isLoading" class="body-section">
        <ChartEntry
        v-for="(entry, i) in chartEntries"
        :number="i + 1"
        :key="i + entry.media_info.artist_song_id"
        :data="entry"
      />
    </div></transition>
    
    <Loader v-if="isLoading" />
  </div>
</template>

<script>
import { chartData } from "../services/fixtures/charts";
import baseAPI from "../services/api";
import ChartEntry from "../components/ChartEntryCard.vue";
import Loader from "../components/Loader.vue";


export default {
  components: { ChartEntry, Loader },
  mounted() {
    // this.fetchCountries();
    this.fetchReleases();
  },
  data() {
    return {
      countries: [],
      entries: [],
      country: "",
      count: 20,
      isLoading: false
    };
  },
  computed: {
      chartEntries() {
          const entries = [];
          this.entries.forEach(e => entries.push({media_info: {...e, afriziki_thumbnail: e.afriziki_thumbnail}}));
          return entries;
      }
  },
  methods: {
    fetchReleases() {
        this.isLoading = true;
      baseAPI
        .get(
          `/public/releases/yt`
        )
        .then((response) => {
          this.entries = response.data.releases;
          // reverse entries

          // this.entries = this.entries.reverse();

          // sort by date created newest first
          this.entries = this.entries.sort((a, b) => {
             return new Date(b.yt_date_created) - new Date(a.yt_date_created);
           });
          this.isLoading = false;

          
        })
        .catch((error) => {console.log(error.toJSON());this.isLoading = false;});
    },
  },
};
</script>

<style scoped>
.hero-img {
  width: 100%;
}

/* .chart-region {
  margin-bottom: 2em;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
} */

.body-section {
  width: 90%;
  margin-top: 4em;
}

/* .date-controls {width: 100%; display: flex; justify-content: flex-start; margin-bottom: 1em;} */

@media all and (max-width: 600px) {
  .body-section {width: 90%;}
}

</style>
